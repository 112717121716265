export const Miles = () => {
  return (
    <>
      <select>
        <option value="25">25</option>
        <option value="50">50</option>
        <option value="100">100</option>
        <option value="500">500</option>
      </select>
    </>
  );
};
